import React from "react";
import { useStaticQuery, graphql, Link, PageProps } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PostList from "../components/PostList";
import PageHeader from "../components/PageHeader";

const IndexPage: React.FC<PageProps> = () => {
  const { edges: posts } = useStaticQuery(graphql`
    query IndexQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: { draft: { ne: true } }
          fileAbsolutePath: { regex: "/posts/.*\\\\.md$/" }
        }
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 5
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              tags
              language
              date(formatString: "MMMM DD, YYYY")
            }
          }
        }
      }
    }
  `).allMarkdownRemark;
  return (
    <Layout>
      <SEO title="Home" />
      <PageHeader title="Recent Posts" />
      <section>
        <PostList posts={posts} />
      </section>
      <section className="pt-4">
        <Link
          className="text-gray-500 transition-colors duration-150 ease-in-out hover:text-red-400 dark:text-gray-400"
          to="/posts"
        >
          More...
        </Link>
      </section>
    </Layout>
  );
};

IndexPage.displayName = "IndexPage";

export default IndexPage;
