import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { slugify } from "transliteration";
import { Edge, Post } from "../models";

const PostList: React.FC<PostListProps> = ({ posts }) => (
  <ul className="space-y-5">
    {posts.map(({ node }) => (
      <li key={node.id}>
        <div className="mb-1 flex flex-wrap items-center space-x-2">
          <time className="block text-sm dark:text-gray-300">
            {node.frontmatter.date}
          </time>
          {node.frontmatter.language ? (
            <div className="px-1 text-xs rounded-md bg-gray-200 dark:bg-gray-600 dark:text-gray-300">
              {node.frontmatter.language}
            </div>
          ) : null}
        </div>
        <Link
          className="font-light text-gray-700 text-2xl transition duration-150 ease-in-out hover:text-red-400 dark:text-gray-200"
          to={`/${slugify(node.frontmatter.title)}`}
        >
          {node.frontmatter.title}
        </Link>
      </li>
    ))}
  </ul>
);

PostList.displayName = "PostList";

PostList.propTypes = {
  posts: PropTypes.array.isRequired,
};

export default PostList;

export type PostListProps = { posts: Edge<Post>[] };
